import {
  Component,
  For,
  Show,
  createResource,
  useContext,
  createEffect,
} from "solid-js";
// import Accordion from "~/components/Accordion";
import { Accordion } from "../ui";
import { Icon } from "solid-heroicons";
import { SiteContext } from "~/utils/contexts";
import { folder } from "solid-heroicons/solid";
import { document, arrowDownTray, eye } from "solid-heroicons/outline";
import { EventType } from "@solid-primitives/analytics";
import { useSessionContext, useSiteContext } from "~/utils/contexts";

import type { Document } from "~/services/roma-api/account/types";

export const DocumentPanel: Component<{
  label: string;
  LastUpdated: number;
  List: Document[];
}> = (props) => {
  const { track } = useSiteContext();
  return (
    <Accordion
      contentHeight="Large"
      icon="Chevron"
      name={props.label}
      labelClass="pr-4"
      label={
        <div class="flex justify-between items-center w-full">
          <div class="grid grid-cols-[max-content_1fr] items-center gap-x-2">
            <Icon path={folder} class="text-roma-blue w-5 block" />
            <h3 class="col-start-2 text-base font-medium">{props.label}</h3>
            <p class="col-start-2 text-sm text-roma-dark-grey font-light">
              Last updated{" "}
              {new Date(props.LastUpdated).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
      }
    >
      <div class="bg-roma-grey px-2 md:px-10 py-4 child:py-4">
        <For each={props.List}>
          {(item) => (
            <div class="flex flex-col md:flex-row justify-between items-center">
              <div class="grid grid-cols-[max-content_1fr] items-center gap-x-2">
                <Icon path={document} class="w-5" />
                <h4 class="font-medium col-start-2">{item.Name}</h4>
                <p class="col-start-2 text-sm text-roma-dark-grey font-light">
                  Last updated{" "}
                  {new Date(item.LastUpdated).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
              </div>
              <div class="flex flex-row md:flex-col max-md:justify-around max-md:w-full max-md: pt-2">
                <a
                  href={item.URL}
                  target="_blank"
                  class="flex gap-2 text-roma-blue"
                  download={item.Name}
                  onClick={() => {
                    track(EventType.Event, {
                      category: "documents",
                      action: "download",
                      value: item.Name,
                    });
                  }}
                >
                  <Icon path={arrowDownTray} class="text-roma-blue w-4" />
                  <span class="block font-medium">Download</span>
                </a>
                <a
                  href={item.URL ? item.URL : item.URL}
                  class="flex gap-2 text-roma-blue"
                  target="_blank"
                  onClick={() => {
                    track(EventType.Event, {
                      category: "documents",
                      action: "download",
                      value: item.Name,
                    });
                  }}
                >
                  <Icon path={eye} class="text-roma-blue w-4" />
                  <span class="block font-medium">View</span>
                </a>
              </div>
            </div>
          )}
        </For>
      </div>
    </Accordion>
  );
};
